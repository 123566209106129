import { TitleOutlined } from 'components/reusables/TitleOutlined'
import styled from 'styled-components'

export const Container = styled.main`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url('/assets/images/BG-6-Privacidade.png') no-repeat 50% 50%;
  object-fit: cover;
  min-height: 500px;
  background-size: 1200px;
  padding: 1rem;
  @media (min-width: 768px) {
    background-size: 100%;
  }

  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    background: none;
    .content-container {
      max-width: 1120px;
      margin: 0 auto;
      margin-bottom: 4rem;
    }
  }
`

export const ContentContainer = styled.div`
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.white};
  @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
    max-width: 50%;
  }
`

export const ContentContainerHeader = styled.header`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  border-radius: 20px 20px 0 0;
  h1 {
    font-size: 30px;
    font-weight: 600;
  }
`

export const ContentContainerBody = styled.div`
  padding: 1rem;
  p {
    font-size: 18px;
    margin-bottom: 1.675rem;
  }
`

export const ContentContainerTitle = styled(TitleOutlined)`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 1.5rem;
`

export const ContentContainerFooter = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
`
export const ContentContainerCredits = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 0.675rem;
    width: 35px;
  }
`

export const CreditsLabel = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  font-size: 0.9rem;
`

export const CreditsName = styled.p`
  color: ${({ theme }) => theme.colors.gray[500]};
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 4px;
`

export const CreditsSubName = styled.p`
  color: ${({ theme }) => theme.colors.gray[500]};
  font-weight: 600;
  font-size: 0.8rem;
  margin-bottom: 4px;
`

export const ContainerBody = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.colors.gray[500]};
  padding: 1rem;
  padding-bottom: 4rem;
  padding-top: 4rem;
  .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media (min-width: ${({ theme }) => theme.sizes.breakpoints.lg}) {
      max-width: 1120px;
      margin: 0 auto;
    }
  }
`
