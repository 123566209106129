import { Link } from 'react-router-dom'
import * as Styled from './styles'

export const CookiesPage = (): JSX.Element => {
  return (
    <>
      <Styled.ContainerBody>
        <div className="container">
          <Styled.ContentContainer>
            <Styled.ContentContainerHeader>
              <h1>Aviso de uso de Cookies</h1>
            </Styled.ContentContainerHeader>
            <Styled.ContentContainerBody>
              <p className="text-sm">
                <strong>Autor:</strong> Enc.de Dados | <strong>Classificação:</strong> Pública/Dados
                Pessoais | <strong>Versão:</strong> 1.1 | <strong>Data de Publicação:</strong>{' '}
                01/12/2024
              </p>

              <p className="text-md">
                <strong>Olá, seja bem-vindo ao nosso Aviso de Uso de Cookies.</strong>
              </p>

              <p className="text-md text-space">
                A Góes & Nicoladelli – Reconhecida legalmente como “
                <strong>Góes & Nicoladelli Advogados Associados</strong>, inscrita no CNPJ:{' '}
                <strong>03.239.823/0001-62</strong>”, localizada na R. Almirante Tamandaré - 114,
                Santa Bárbara, Criciúma/SC - Cep.: 88.804-290, Controladora de Dados Pessoais, em
                conformidade com as normas legais vigentes relacionadas a proteção de dados
                pessoais, estabelece este <strong>Aviso de Privacidade</strong> com o objetivo de
                demonstrar transparência e disponibilizar informações relevantes para que os
                titulares dos dados pessoais possam exercer seus direitos e obtenham as respostas de
                que precisam antes, durante e ao longo do ciclo de vida dos dados sob sua
                responsabilidade como controlador.
              </p>

              <p className="text-md text-space">
                <strong>1.0 Medidas de Segurança e Privacidade</strong>
                <br />A <strong>Góes & Nicoladelli</strong> estabelece e implementa as melhores
                práticas em segurança da informação e privacidade, adotando padrões, tecnologias e
                capacitação contínua das pessoas envolvidas no tratamento de dados pessoais para
                atender aos requisitos internos e externos, promovendo melhorias contínuas em nossos
                processos. A <strong>Góes & Nicoladelli</strong>, usa os cookies conforme descrito
                no item 5, e para isso busca o seu consentimento, realizamos tratamento dos dados ao
                mínimo possível nas atividades de negócios e tecnológicos. Os cookies ou tecnologias
                similares podem utilizar os dados obtidos no acesso ao website para possibilitar a
                navegação. Os cookies ou links de terceiros inseridos neste website possuem suas
                próprias políticas de cookies, políticas de privacidade, políticas de uso e devem
                ser consultadas conforme os usuários ache pertinente no website do terceiro.
              </p>

              <p className="text-md text-space">
                <strong>2.0 Se Não Permitir os Cookies?</strong>
                <br />
                Ao não permitir os cookies, desconfigurações no website ou problema de acesso podem
                ocorrer. Por questões tecnológicas os cookies que julgamos funcionais estarão
                habilitados por padrão. Você pode consultar o propósito do cookie e tempo que ele
                permanecerá em seu navegador. Ele permanecerá até que você saia do website, exclua
                ou expire conforme configurado. É importante que você entenda que apenas informações
                de conexões como IP/Porta de comunicação serão utilizados neste processo. Não
                coletamos ou capturamos quaisquer outras informações pessoas, para mais informações
                consulte o nosso Aviso de <Link to="/aviso-privacidade">Privacidade</Link>.
              </p>

              <p className="text-md text-space">
                <strong>3.0 Consentimento de Crianças/Adolescentes</strong>
                <br />A <strong>Góes & Nicoladelli</strong> não realiza o tratamento e obtém
                consentimento de crianças/adolescentes intencionalmente. Mas podem ocorrer acesso
                que adolescentes fazem para cadastro em nossas vagas de trabalhos, estágios ou
                programas similares. Compreendemos que os acessos destes são feitos com o
                consentimento dos pais e responsáveis em seus equipamentos, de qualquer forma, as
                informações acessadas neste website não implicariam nos direitos e garantias de
                melhor interesse deste grupo de titular.
              </p>

              <p className="text-md text-space">
                <strong>4.0 Remoção dos Cookies</strong>
                <br />
                Nosso aviso de uso de cookies atende às determinações previstas nas leis vigentes
                sobre o tema. Os cookies podem ser armazenados pelo prazo máximo de 1 (um) ano ou
                até que o cache do navegador seja limpo ou as configurações do navegador sejam
                alteradas. Você pode, a qualquer momento, retirar o consentimento dado para o
                tratamento de dados conforme a listagem abaixo, para isso você não precisa de
                interações conosco. Se o seu navegador não estiver na lista abaixo, por favor,
                pesquisa junto ao desenvolvedor da aplicação como realizar a remoção dos cookies.
                <br />
                <br />
                <ul>
                  <li>
                    Google Chrome:{' '}
                    <a
                      href="https://support.google.com/chrome/answer/95647?hl=pt-BR"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://support.google.com/chrome/answer/95647?hl=pt-BR
                    </a>
                  </li>
                  <li>
                    Internet Explorer:{' '}
                    <a
                      href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#:~:text=Para%20excluir%20cookies,do%20site%20e%20selecione%20Excluir"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#:~:text=Para%20excluir%20cookies,do%20site%20e%20selecione%20Excluir
                    </a>
                  </li>
                  <li>
                    Mozila Firefox:{' '}
                    <a
                      href="https://support.mozilla.org/pt-BR/kb/limpe-cookies-e-dados-de-sites-no-firefox"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://support.mozilla.org/pt-BR/kb/limpe-cookies-e-dados-de-sites-no-firefox
                    </a>
                  </li>
                  <li>
                    Microsoft Edge:{' '}
                    <a
                      href="https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09#:~:text=Abra%20o%20Microsoft%20Edge%20e,e%20ent%C3%A3o%20selecione%20Limpar%20agora"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09#:~:text=Abra%20o%20Microsoft%20Edge%20e,e%20ent%C3%A3o%20selecione%20Limpar%20agora
                    </a>
                  </li>
                </ul>
              </p>

              <p className="text-md text-space">
                <strong>5.0 Conhecimentos e Concordâncias de Tratamento</strong>
                <br />
                Leia atentamente as condições de tratamento dos dados pessoais destacados abaixo.
              </p>

              <p className="text-md text-space">
                <strong>5.1 Cookie Banner</strong>
                <br />

                <table className="table">
                  <tbody>
                    <tr>
                      <td width="20%">
                        <strong>Visão Geral do Serviço</strong>
                      </td>
                      <td>Acesso ao site institucional da Góes & Nicoladelli</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Propósito do Uso</strong>
                      </td>
                      <td>Informar o titular sobre o uso de cookies, condições e aceite</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Controlador dos DP</strong>
                      </td>
                      <td>Góes & Nicoladelli Advogados Associados</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>DP a serem coletados</strong>
                      </td>
                      <td>Protocolo de Internet (IP) e Porta de Comunicação de serviços</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Horário e Local da Coleta</strong>
                      </td>
                      <td>No acesso ao site institucional</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Método do Uso</strong>
                      </td>
                      <td>Processados por sistemas computacionais e telecomunicações</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Geolocalização dos Dados</strong>
                      </td>
                      <td>Brasil e Estados Unidos</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Transferência a Operadores</strong>
                      </td>
                      <td>Verdadeiro (Fornecedores de serviços)</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Período de Retenção e Descarte</strong>
                      </td>
                      <td>Até 2 anos</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Sua participação e Escolhas atuais</strong>
                      </td>
                      <td>Fornecer e Atualizar</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Solicitações e Reclamações</strong>
                      </td>
                      <td>
                        <Link to="/privacidade">
                          https://www.goesnicoladelli.adv.br/privacidade
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Base legal</strong>
                      </td>
                      <td>Mediante o fornecimento de consentimento pelo titular</td>
                    </tr>
                  </tbody>
                </table>
              </p>

              <p className="text-md text-space">
                <strong>5.2 Cookie de Sessão</strong>

                <table className="table">
                  <tbody>
                    <tr>
                      <td width="20%">
                        <strong>Visão Geral do Serviço</strong>
                      </td>
                      <td>Acesso ao site institucional da Góes & Nicoladelli</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Propósito do Uso</strong>
                      </td>
                      <td>Armazenar sessão da navegação do site institucional.</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Controlador dos DP</strong>
                      </td>
                      <td>Góes & Nicoladelli Advogados Associados</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>DP a serem coletados</strong>
                      </td>
                      <td>Protocolo de Internet (IP) e Porta de Comunicação de serviços</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Horário e Local da Coleta</strong>
                      </td>
                      <td>No acesso ao site institucional</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Método do Uso</strong>
                      </td>
                      <td>Processados por sistemas computacionais e telecomunicações</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Geolocalização dos Dados</strong>
                      </td>
                      <td>Brasil e Estados Unidos</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Transferência a Operadores</strong>
                      </td>
                      <td>Verdadeiro (Fornecedores de serviços)</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Período de Retenção e Descarte</strong>
                      </td>
                      <td>Durante a navegação ao site</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Sua participação e Escolhas atuais</strong>
                      </td>
                      <td>Fornecer e Atualizar</td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Solicitações e Reclamações</strong>
                      </td>
                      <td>
                        <Link to="/privacidade">
                          https://www.goesnicoladelli.adv.br/privacidade
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%">
                        <strong>Base legal</strong>
                      </td>
                      <td>Mediante o fornecimento de consentimento pelo titular</td>
                    </tr>
                  </tbody>
                </table>
              </p>
              <p className="text-md text-space">
                <strong>6.0 Para Ajuda e Requisição de Direitos</strong>
                <br />A <strong>Góes & Nicoladelli</strong>, visando a transparência e o respeito
                aos direitos dos titulares, disponibiliza o canal abaixo para o caso de
                discordâncias, dúvidas e comentários a qualquer momento em:{' '}
                <Link to="/privacidade">https://www.goesnicoladelli.adv.br/privacidade</Link>.
              </p>
              <p className="text-md text-space">
                <strong>7.0 Incidentes</strong>
                <br />A <strong>Góes & Nicoladelli</strong> atuará vigorosamente ao responder os
                incidentes de segurança da informação para eliminar e mitigar seus potenciais riscos
                aos titulares de dados. Os incidentes serão reportados aos titulares e autoridades
                competente para conhecimento e providencias criminais ações causadas.
              </p>
              <p className="text-md text-space">
                <strong>8.0 Do Foro</strong>
                <br />
                Para a solução de controvérsias decorrentes do presente instrumento será aplicado
                integralmente a legislação brasileira. Os eventuais litígios deverão ser
                apresentados no foro da comarca em que se encontra a sede da empresa.
              </p>
              <p className="text-md text-space">
                <strong>9.0 Atualização deste Aviso</strong>
                <br />A <strong>Góes & Nicoladelli</strong> reserva-se o direito de realizar
                alterações nesta documentação a qualquer momento, a fim de adequá-la às atividades
                realizadas, atender a questões legais e de negócios.
              </p>
            </Styled.ContentContainerBody>
          </Styled.ContentContainer>
        </div>
      </Styled.ContainerBody>
    </>
  )
}
